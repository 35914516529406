<template>
<Form ref="form" :model="form" label-position="top" :show-message="false">
  <FormItem class="my-form-item" v-if="['handCmd'].includes(command.code)" prop="content" label="数据内容">
      <Input type="textarea" v-model="form.content"></Input>
  </FormItem>
  <FormItem class="my-form-item" v-if="['handCmd'].includes(command.code)" prop="content" label="发送方式">
    <Checkbox v-model="form.ascii">ASCII码发送</Checkbox>
  </FormItem>
  <FormItem class="my-form-item" v-if="['config4G'].includes(command.code)" prop="content" label="4G通道">
    <Select v-model="form.channel">
      <Option value="A">A</Option>
      <Option value="B">B</Option>
      <Option value="C">C</Option>
      <Option value="D">D</Option>
    </Select>
  </FormItem>
  <FormItem class="my-form-item" v-if="['config4G'].includes(command.code)" prop="content" label="服务器IP">
    <Input type="text" v-model="form.server" placeholder="请输入IP"></Input>
  </FormItem>
  <FormItem class="my-form-item" v-if="['config4G'].includes(command.code)" prop="content" label="服务端口">
    <Input type="number" v-model="form.port" placeholder="请输入端口"></Input>
  </FormItem>
  <FormItem class="my-form-item" :label-width="0" style="text-align: center;">
    <Button type="primary" size="default" @click="send" style="margin-right: 10px;">发送指令</Button>
    <Button type="error" size="default" @click="clear">清除记录</Button>
  </FormItem>
</Form>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'CommandArgs',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    command: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      mains: [],
      devices: [],
      form: {
        content: '', //手工发送的数据
        ascii: false, //是否ASCII码发送
        channel: 'B', //4g通道
        server: '211.162.119.217', //ip
        port: '44440', //端口
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // console.log('modal level edit mounted', this.item)
        // this.isAdd = this.item.isAdd;
        // if(!this.isAdd){
        //   this.form.id = this.item.data.id;
        //   this.form.name = this.item.data.name;
        //   this.form.code = this.item.data.code;
        // }else{
        //   this.form.id = 0;
        //   this.form.name = '';
        //   this.form.code = 1;
        // }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
  },
  mounted: function(){
  },
  methods: {
    send: function(){
      let args = null;
      switch(this.command.code){
        case 'handCmd':{
          if(this.form.content.length == 0){
            this.$Message.warning('指令内容不能为空');
            return;
          }
          args = {ascii: this.form.ascii, content: this.form.content};
          break;
        }
        case 'config4G': {
          if(this.form.server.length == 0){
            this.$Message.warning('服务器IP不能为空');
            return;
          }
          args = {channel: this.form.channel, server: this.form.server, port: this.form.port};
          break;
        }
        default: {
          this.$Message.warning('未识别的指令类型');
          return;
        }
      }
      this.$emit('send', {cmd: this.command.code, args: args});
    },
    clear: function(){
      this.$emit('send', {cmd: '$$$clearTransMessage'});
    },
  }
}
</script>
<style scoped>
.my-form-item{
  margin-bottom: 8px;
}
>>> .my-form-item >.ivu-form-item-label{
  float: none;
  display: inline-block;
  padding: 0 0 3px 0;
}
</style>