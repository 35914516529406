<template>
<div class="data-area">
  <div class="conn-area">
    <div class="filter-area">
      <div class="filter-title" style="flex: auto;">在线连接数：{{ tabData.length }}</div>
      <Button type="primary" style="margin-right: 5px" @click="getList">刷新连接</Button>
      <!-- <Input v-model="devcode" clearable :maxlength="20" placeholder="请输入设备ID" style="width: 150px; flex:none;"></Input> -->
    </div>
    <div class="filter-area">
      <!-- <div style="flex: auto;">在线连接数：{{ tabData.length }}</div> -->
      <!-- <Button type="primary" long style="margin-right: 5px" @click="getList">在线连接数：{{ tabData.length }}</Button> -->
      <Input v-model="devcode" clearable :maxlength="20" placeholder="请输入设备ID过滤设备连接信息"></Input>
    </div>
    <div class="list-area">
      <template v-for="(item, idx) in tabData">
        <div v-if="item.code.includes(devcode)" :class="['item-area', selectedCode == item.code ? 'selected' : '']" :key="idx" @click.stop="selectItem(item)">
          <div class="item-code" title="通信ID">{{ item.code }}</div>
          <div class="item-conn">
            <div class="conn-time" title="连接时间">{{ item.ctime }}</div>
            <div class="conn-name" title="远程IP及端口">{{ item.conn }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <div class="device-area">
    <div class="filter-area">
      <div class="filter-title" style="flex: auto; text-align: center;">设备信息</div>
    </div>
    <div class="info-item">
      <div class="info-title">所在客户</div>
      <div class="info-content">{{ editItem.custName || '-' }}</div>
    </div>
    <div class="info-item">
      <div class="info-title">设备大类</div>
      <div class="info-content">{{ editItem.bigTypeName || '-' }}</div>
    </div>
    <div class="info-item">
      <div class="info-title">设备类型</div>
      <div class="info-content">{{ editItem.deviceTypeName || '-' }}</div>
    </div>
    <div class="info-item">
      <div class="info-title">ICCID</div>
      <div class="info-content">{{ editItem.iccid || '-' }}</div>
    </div>
    <div class="info-item">
      <div class="info-title">IMEI</div>
      <div class="info-content">{{ editItem.imei || '-' }}</div>
    </div>
    <div class="info-item">
      <div class="info-title">硬件版本</div>
      <div class="info-content">{{ editItem.hardware || '-' }}</div>
    </div>
    <div class="info-item">
      <div class="info-title">软件版本</div>
      <div class="info-content">{{ editItem.software || '-' }}</div>
    </div>
    <div class="info-item">
      <div class="info-title">应用编码</div>
      <div class="info-content">{{ editItem.appcode || '-' }}</div>
    </div>
    <div class="info-item">
      <div class="info-title">应用版本</div>
      <div class="info-content">{{ editItem.appversion || '-' }}</div>
    </div>
    <div class="filter-area">
      <div class="filter-title" style="flex: auto; text-align: center;">指令列表</div>
    </div>
    <div class="cmd-list">
      <Button type="error" size="default" style="margin-right: 5px; margin-bottom: 5px;" @click="disconnect">断开连接</Button>
      <Button v-for="(cmd, idx) in commands" :key="idx" type="info" size="default" style="margin-right: 5px; margin-bottom: 5px;" @click="clickCmd(cmd)">{{cmd.name}}</Button>
    </div>
  </div>
  <div class="device-area">
    <div class="filter-area">
      <div class="filter-title" style="flex: auto; text-align: center;">指令参数</div>
    </div>
    <div class="args-area">
      <CommandArgs v-if="excuteCommand.code && excuteCommand.code.length > 0" :command="excuteCommand" @send="commandSend" />
    </div>
    <div class="filter-area">
      <div class="filter-title" style="flex: auto; text-align: center;">指令结果</div>
    </div>
    <div class="result-area"></div>
  </div>
  <div class="work-area">
    <!-- <div class="tool-area"></div> -->
    <div class="list-area" ref="msgList">
      <template v-for="(item, idx) in trans">
        <div class="cust-item" :key="idx">
          <div class="item-header">
            <div class="item-name" :style="{color: item.send ? 'blue' : 'green'}">{{item.code}}</div>
            <div class="item-time" :style="{color: item.send ? 'blue' : 'green'}">{{item.ts}}</div>
            <div class="item-type" :style="{color: item.send ? 'blue' : 'green'}" @click="showPrococol(item)">{{item.send ? '发送' : '接收'}}</div>
            <div class="item-diff tr" :style="{color: item.send ? 'blue' : 'green'}">{{getTimeDiff(item, idx)}}</div>
          </div>
          <div class="item-content" :style="{color: item.send ? 'blue' : 'green'}">{{item.bytes}}</div>
        </div>
      </template>
    </div>
  </div>
  <ModalProtocolResult v-model="showDebugModal" :item="debugItem" />
</div>
</template>
<script>
import { mapState } from 'vuex'
// import ModalDebug from './ModalDebug'
import ModalProtocolResult from '../trans/ModalProtocolResult'
import exportXlsx from '@/components/xlsx/exportXlsx'
import CommandArgs from './CommandArgs'
export default {
  name: 'ModalTransOnlines',
  components:{
    CommandArgs,
    ModalProtocolResult,
    // ModalDebug
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showModal: this.value,
      showDebugModal: false,
      loading: false,
      curTab: 'group',
      tabHeight:1,
      tabData:[//列表数据
      ],
      tabHead:{ //头部标题
        conn: "客户端",
        code: '注册包',
        ctime: '连接时间',
        ttime: "通信时间",
      //  devid: '是否关联设备',
        name: '设备名称',
        type: '类型名称',
      },
      editItem: {},
      debugItem: {},
      devcode: '',
      selectedCode: '',
      filterData: [],
      commands: [],
      excuteCommand: {},
    }
  },
  computed: {
    ...mapState('cmd', ['trans', 'monitorDeviceId']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      // if(newVal){
      //   this.getList();
      // }
    },
    selectedCode(){
      // console.log('sel code changed', this.selectedCode)
      this.getDebugInfo();
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
    // trans(){
    //   this.$refs.msgList.scrollTop = this.$refs.msgList.scrollHeight - this.$refs.msgList.clientHeight + 50;
    //   console.log("new msg got", this.$refs.msgList.scrollHeight, this.$refs.msgList.clientHeight,this.$refs.msgList.scrollTop);
    //   // this.$refs.msgList.scrollTo(0, this.$refs.msgList.clientHeight - this.$refs.msgList.scrollHeight)
    // }
  },
  mounted: function(){
    this.getList();
  },
  destroyed: function(){
    this.$store.commit('cmd/disableMonitorDevices');
  },
  methods: {
    clickCmd: function(params){
      // console.log('click cmd', params)
      this.excuteCommand = params;
    },
    commandSend: function(params){
      // console.log('set cmd args', params)
      if(params.cmd == '$$$clearTransMessage'){
        this.$store.commit('cmd/clearTransData');
        return;
      }
      params.code = this.selectedCode;
      this.$axios.post(`site/debug/SendCommand`, params).then(res => {
        if(res.code != 0)return;
        this.$Message.warning('指令已发送');
      });
    },
    disconnect: function(){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要断开连接[${this.selectedCode}]吗?`,
        onOk: async () => {
          this.$axios.post(`site/debug/Disconnect`, {code: this.selectedCode}).then(res => {
            this.selectedCode = '';
            this.$store.commit('cmd/disableMonitorDevices');
            this.$store.commit('cmd/clearTransData');
            if(res.code == 0){
              this.$Message.info("已断开连接");
              this.getList();
            }else{
              // this.$Message.error(res.data);
            }
          });
        }
      });
    },
    showPrococol: function(params){
      console.log('show protocol 2', params);
      if(params.send)return;
      this.debugItem = {id: params.code, content: params.bytes };
      this.showDebugModal = true;
    },
    getTimeDiff: function(item, idx){
      if(idx == 0)return 0;
      let last = this.trans[idx - 1];
      return new Date(item.ts).getTime() - new Date(last.ts).getTime();
    },
    selectItem: function(params){
      this.selectedCode = params.code;
      this.$store.commit('cmd/disableMonitorDevices');
      this.$store.commit('cmd/clearTransData');
      this.$store.commit('cmd/enableMonitorDevices', this.selectedCode);
    },
    handelFilterData(){
      // console.log('get trans list', this.tabData)
      if(this.devcode.length > 0){
        this.$set(this, 'filterData', this.tabData.filter(p => p.code && p.code.indexOf(this.devcode) != -1));
      }else{
        this.$set(this, 'filterData', this.tabData);
      }
    },
    handleDebug: function(params){
      this.editItem = {data: params.row.conn, code: params.row.code};
      this.showDebugModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要断开连接[${params.row.conn}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/search/DeleteTrans`, {id: params.row.conn}).then(res => {
            if(res.code == 0){
              this.$Message.info("已断开连接");
              this.getList();
            }else{
              // this.$Message.error(res.data);
            }
          });
        }
      });
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
    exportData(){
      if(this.tabData.length == 0){
        this.$Message.warning('当前无数据');
        return;
      }
      let name = '设备在线连接导出';
      exportXlsx(this.tabData, this.tabHead, name);
    },
    getDebugInfo: function(){
      this.$store.commit('cmd/disableMonitorDevices');
      this.$store.commit('cmd/clearTransData');
      if(this.selectedCode.length == 0)return;
      this.$axios.post(`site/debug/QueryDeviceInfo`, {code: this.selectedCode}).then(res => {
        this.$store.commit('cmd/enableMonitorDevices', this.selectedCode);
        // console.log('selected code changed', this.selectedCode, res.data);
        if(res.code != 0)return;
        this.$set(this, 'editItem', res.data.info);
        res.data.cmds.splice(0, res.data.cmds.length, {
          code: 'handCmd',
          name: '手动发送',
          needArgs: true,
        }, {
          code: 'config4G',
          name: '配置4G',
          needArgs: true,
        })
        this.$set(this, 'commands', res.data.cmds);
        this.excuteCommand = {};
      });
    },
    getList(){
      this.loading = true;
      this.$axios.post(`station/search/QueryTrans`, {}).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
          //  console.log(res.data)
        }else{
          // res.data.map(item => {
          //   item.name = item.devid > 0 ? item.name : '';
          //   item.type = item.devid > 0 ? item.type : '';
          //   item.devid = item.devid > 0 ? '已关联' : '未关联';

          // })
          this.$set(this, 'tabData', res.data);
          if(res.data.length > 0){
            this.selectedCode = res.data[0].code;
          }
          // this.handelFilterData();
        }
      });
    },
    cancel: function(){
      this.showModal = false;
    }
  }
}
</script>
<style scoped>
.args-area{
  /* border: solid 1px rgb(135, 159, 224); */
  height: 60%;
  flex: auto;
}
.result-area{
  /* border-top: solid 1px rgb(135, 159, 224); */
  height: 40%;
  flex: auto;
}
.data-area{
  /* border: solid 1px red; */
  height: 100%;
  width: 100%;
  display: flex;
}
.conn-area{
  border: solid 1px rgb(135, 159, 224);
  width: 350px;
  flex: none;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.device-area{
  border: solid 1px rgb(135, 159, 224);
  width: 350px;
  flex: none;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.filter-area{
  border-bottom: solid 1px rgb(135, 159, 224);
  height: 36px;
  flex: none;
  margin-bottom: 5px;
  display: flex;
  align-items:center;
  padding-bottom: 3px;
}
.info-item{
  height: 30px;
  line-height: 30px;
  flex: none;
  border-bottom: solid 1px rgb(135, 159, 224);
  display: flex;
}
.cmd-list{
  height: 300px;
  flex: auto;
  overflow-y: auto;
}
.info-title{
  /* border: solid 1px rgb(135, 159, 224); */
  width: 80px;
  flex: none;
  text-align: right;
  padding-right: 10px;
}
.info-content{
  /* border: solid 1px rgb(135, 159, 224); */
  width: 80px;
  flex: auto;
  font-weight: bold;
  font-size: 15px;
}
.filter-title{
  font-weight: bolder;
  font-size: 20px;
}
.work-area{
  /* border: solid 1px red; */
  width: 500px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.tool-area{
  border: solid 1px rgb(135, 159, 224);
  height: 100px;
  flex: none;
  margin-bottom: 10px;
}
.list-area{
  border: solid 1px rgb(135, 159, 224);
  padding: 0 5px;
  height: 100px;
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 5px;
}
.cust-item{
  border: solid 1px rgba(128, 128, 128, 0.274);
  margin-top: 5px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.item-header{
  height: 20px;
  width: 100%;
  display: flex;
}
.item-name{
  width: 100px;
  flex: auto;
  margin-right: 10px;
  /* border-right: solid 1px gray; */
  white-space: nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.item-time{
  width: 170px;
  flex: none;
  /* border-right: solid 1px gray; */
}
.item-type{
  width: 30px;
  flex: none;
  
  /* border: solid 1px gray; */
}
.item-diff{
  width: 50px;
  flex: none;
  /* border: solid 1px gray; */
}
.item-content{
  width: 100%;
  border-top: solid 1px rgba(128, 128, 128, 0.274);
  padding-top: 5px;
  /* white-space: nowrap; */
}
.cust-item:hover{
  border: solid 1px blue;
}
.item-area{
  border: solid 1px rgb(135, 159, 224);
  height: 50px;
  flex: none;
  display: flex;
  margin-top: 5px;
  cursor: pointer;
}
.selected {
  background-color: rgba(53, 13, 196, 0.678) !important;
  color: white;
}
.item-area:hover{
  background-color: rgba(53, 13, 196, 0.178);
}
.item-code{
  border-right: solid 1px rgb(135, 159, 224);
  width: 100px;
  flex: auto;
  line-height: 50px;
}
.item-conn{
  /* border: solid 1px rgb(135, 159, 224); */
  width: 200px;
  flex: none;
  display: flex;
  flex-direction: column;
}
.conn-time{
  border-bottom: solid 1px rgb(135, 159, 224);
  height: 25px;
  flex: none;
  line-height: 25px;
  padding-left: 5px;
}
.conn-name{
  /* border: solid 1px rgb(135, 159, 224); */
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  flex: none;
}
.search-data-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header{
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content{
  height: 100px;
  flex: auto;
  /* border: solid 1px blue; */
}
.search-data-query{
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
  justify-content: flex-end;
}
.search-data-view{
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
</style>